import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import BodyClass from './bodyClass';


export default function PageLoading({text}: {text ?: string}) {
  return(
    <>
    <div className='text-dark d-flex align-items-center justify-content-center page-loading-height'>
      <div>
        <div className='text-center pb-0'>
          <FontAwesomeIcon icon={faSpinner} className='fa-spin' size='2x' style={{width: '2em'}} />
        </div>
        {
          text &&
          <div className='align-self-center mb-3'>
            <span>{text}</span>
          </div>
        }
      </div>
    </div>
    </>
  )
}

export function TransparentPageLoading({isFixedLoading}: {isFixedLoading?: boolean}) {
  return (
    <div className={`${isFixedLoading ? 'fixed-loading' : '' } transparent-page-loading`}>
      <FontAwesomeIcon icon={faSpinner} className='fa-spin' size='2x' style={{ width: '2em' }} />
    </div>
  )
}
  

export function LoadingOrders({text}: {text ?: string}) {
  return(
    <>
    <BodyClass bodyClass=''/>
    <div className='text-dark d-flex align-items-center justify-content-center js-page-loading'>
      <div>
        <div className='text-center pb-0'>
          <FontAwesomeIcon icon={faSpinner} className='fa-spin' size='2x' style={{width: '2em'}} />
        </div>
        {
          text &&
          <div className='align-self-center mb-3'>
            <span>{text}</span>
          </div>
        }
      </div>
    </div>
    </>
  )
}

export function LoadingEvents() {
  return(
    <>
    <BodyClass bodyClass=''/>
    <div className='loading-events d-flex align-items-center justify-content-center js-page-loading'>
      <div>
        <div className='text-center pb-0'>
          <FontAwesomeIcon icon={faSpinner} className='fa-spin' size='2x' style={{width: '2em'}} />
        </div>
      </div>
    </div>
    </>
  )
}