import { useEffect } from "react";
import { useRouter } from "next/router";
import getConfig from 'next/config'
import PageLoading from '../components/pageLoading';
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export default function Login() {
  const router = useRouter();
  useEffect(() => {
    router.push('/')
  }, [])
  return <PageLoading />
}